<template>
  <div class="page-content" v-if="missionReward">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Mission Reward"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Mission Reward</h2>
      <b-card class="mt-1">
        <validation-observer
          ref="createForm"
          v-slot="{ handleSubmit }"
          class="mr-4 ml-4"
        >
          <b-form @submit.stop.prevent="handleSubmit()">
            <validation-provider
              name="Reward type"
              v-slot="validationContext"
              :rules="{ required: true }"
            >
              <b-form-group label="Ordinal Of Week" label-for="h-reward" label-cols-md="3">
                <b-form-input v-model="missionReward.type" :state="getValidationState(validationContext)" />
                <b-form-invalid-feedback id="input-category-name-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="Reward image url"
              v-slot="validationContext"
              :rules="{ required: true, url: true }"
            >
              <b-form-group label="Ordinal Of Week" label-for="h-reward" label-cols-md="3">
                <b-form-input v-model="missionReward.image" :state="getValidationState(validationContext)" />
                <b-img
                  v-if="missionReward.image"
                  :src="missionReward.image"
                  class="mt-2"
                  alt="reward image"
                  fluid-grow
                />
                <b-form-invalid-feedback id="input-category-name-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-card>
    </section>

    <action-buttons
      update-button
      back-button
      @update="update"
      :is-updating="isUpdating"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { url } from "@/@core/utils/validations/validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      isUpdating: false,
      isDeleting: false,
      missionReward: null,
      lessons: [],
      categories: [
        {
          text: "Pronun",
          value: "pronun",
        },
      ],
      selectRewards: [
        {
          text: "Gem",
          value: "GEM",
        },
        {
          text: "Token",
          value: "TOKEN",
        },
      ],
    };
  },

  computed: {
    missionRewardId() {
      return this.$route.params.reward_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Mission Reward List",
          to: { name: "mission-reward-list" },
        },
      ];
      items.push({ text: "Mission Reward Detail", active: true });
      return items;
    },
  },
  watch: {
    "missionReward.type"(val) {
      this.missionReward.type = val.toUpperCase();
    },
  },

  async created() {
    this.getMissionReward();
  },
  methods: {
    async getMissionReward() {
      this.missionReward = await service.get({ id: this.missionRewardId });
    },

    async update() {
      let validation = await this.$refs.createForm.validate();
      if (validation) {
        this.isUpdating = true;
        await service.update({
          data: JSON.stringify(this.missionReward),
        });
        this.isUpdating = false;
      }
    },

    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
